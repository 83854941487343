.dropdown-comp {
  position: absolute;
  z-index: 200;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  // padding: 5px;
  border-radius: 2px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 1px solid #c8c8ce;
  background-color: #fff;
}

@import "custom";
@import "blockui";

.container__page {
  // background-color: #fff;
  .container__page-title {
    font-family: Manrope;
    font-size: 36px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 120%;
    color: #000;
  }
  .container__table {
    margin-top: 32px;
    border-radius: 8px;
    background-color: #fefefe;
    box-sizing: border-box;
    padding: 10px 0;
    .container__table-header {
      .container__table-title {
        height: 70px;
        font-family: Manrope;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 120%;
        color: #000;
        padding: 0 26px;
        align-items: center;
        display: flex;
      }
      .container__table-submenu {
        // margin-top: 20px;
        display: flex;
        justify-content: space-between;
        padding: 14px 26px;
        align-items: center;
      }
    }
  }
  .container__card {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
  }
}

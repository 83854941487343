button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 0.6;
  }
  &:focus {
    outline: 0;
  }

  &:disabled {
    .filter-icon {
      filter: invert(69%) sepia(25%) saturate(236%) hue-rotate(176deg)
        brightness(89%) contrast(92%) !important;
    }
  }
}

.button-styles {
  border-radius: 8px;
  font-weight: 500;
  font-style: normal;
  text-align: center;

  &.primary {
    background: #111111;
    color: #f7b500;

    .filter-icon {
      filter: invert(79%) sepia(20%) saturate(7159%) hue-rotate(5deg)
        brightness(100%) contrast(104%);
    }

    &:hover {
      background: #334155;
    }
    &:active {
      background: #303030;
    }
    &:disabled {
      background: #e2e8f0;
      color: #94a3b8;
      cursor: not-allowed;
    }
  }

  &.secondary {
    border: 1.4px solid #111111;
    background-color: #f5f5f5;
    color: #111111;
    &:hover {
      background: #f8fafc;
    }
    &:active {
      background: #e2e8f0;
    }
    &:disabled {
      background: #f8fafc;
      border: 1.4px solid #94a3b8;
      color: #94a3b8;
      cursor: not-allowed;
    }
  }

  &.tertairy {
    background-color: #fff;
    color: #f89200;

    .filter-icon {
      filter: invert(60%) sepia(78%) saturate(2992%) hue-rotate(7deg)
        brightness(104%) contrast(106%);
    }

    &:hover {
      color: #9d5c00;
      .filter-icon {
        filter: invert(31%) sepia(53%) saturate(2230%) hue-rotate(25deg)
          brightness(97%) contrast(101%);
      }
    }
    &:active {
      background: #fdf8e1;
      color: #9d5c00;
      .filter-icon {
        filter: invert(31%) sepia(53%) saturate(2230%) hue-rotate(25deg)
          brightness(97%) contrast(101%);
      }
    }
    &:disabled {
      color: #94a3b8;
      cursor: not-allowed;
    }
  }

  &.reset {
    border: 1.4px solid #e93c3c;
    background-color: #fff;
    color: #eb5757;

    .filter-icon {
      filter: invert(59%) sepia(64%) saturate(4297%) hue-rotate(328deg)
        brightness(99%) contrast(86%);
    }

    &:hover {
      color: #ee7171;
      .filter-icon {
        filter: invert(83%) sepia(29%) saturate(5688%) hue-rotate(310deg)
          brightness(95%) contrast(95%);
      }
    }
    &:active {
      color: #f50808;
      .filter-icon {
        filter: invert(10%) sepia(86%) saturate(7451%) hue-rotate(0deg)
          brightness(109%) contrast(95%);
      }
    }
    &:disabled {
      color: #94a3b8;
      cursor: not-allowed;
    }
  }

  &.lg {
    width: 149px;
    height: 52px;
    font-size: 16px;
  }

  &.md {
    width: 141px;
    height: 44px;
    font-size: 16px;
  }

  &.sm {
    width: 127px;
    height: 40px;
    font-size: 14px;
  }

  &.xs {
    width: 119px;
    height: 32px;
    font-size: 14px;
  }
}

// font
@font-face {
  font-family: "Avenir";
  src: url(../assets/fonts/Avenir-Medium-09.ttf);
}

@font-face {
  font-family: "Quicksand";
  src: url(../assets/fonts/Quicksand-Regular.ttf);
}
@font-face {
  font-family: "Quicksand-Bold";
  src: url(../assets/fonts/Quicksand-Bold.ttf);
}
@font-face {
  font-family: "Manrope";
  src: url(../assets/fonts/Manrope-Regular.ttf);
}
@font-face {
  font-family: "ManropeBold";
  src: url(../assets/fonts/Manrope-Bold.ttf);
}

body {
  font-family: Manrope !important;
  background-color: #f6f6f6 !important;
  // -webkit-user-select: none; /* Safari */
  // -ms-user-select: none; /* IE 10 and IE 11 */
  // user-select: none; /* Standard syntax */
}

.cursor-pointer {
  cursor: pointer;
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

i {
  cursor: pointer;
}

.css-1bggegk .PrivateDateRangePickerToolbar-penIcon {
  position: relative;
  top: 4px;
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  // -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

input::placeholder {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.bounce {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
  // font-size: 60px;
  span {
    position: relative;
    display: inline-block;
    // color: #fff;
    // text-transform: uppercase;
    animation: waviy 2s infinite;
  }
}

@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-5px);
  }
}

.member-type {
  padding: 5px 14px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 120px;
  text-align: center;
  color: #fefefe;
  &.basic {
    background-image: linear-gradient(141deg, #ffdd64 21%, #ecaa44 81%);
  }
  &.premium {
    background-image: linear-gradient(
      146deg,
      #646464 25%,
      #797979 52%,
      #1c1c1c 72%
    );
  }
}

.arrow-bottom {
  img {
    transform: rotate(-90deg);
    width: 14px;
    height: 14px;
  }
}
.arrow-up {
  img {
    transform: rotate(90deg);
    width: 14px;
    height: 14px;
  }
}

.gap-1 {
  gap: 10px;
}

.gap-2 {
  gap: 20px;
}

.bg {
  background-color: rgba(0, 0, 0, 0.7);
}

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999999;
  .img-load {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .spin {
      animation: spin 2s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 100%;
  margin: 0.5rem;
  border-radius: 0.25rem;
}

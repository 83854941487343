.input-comp {
  position: relative;
  width: 100%;
  input {
    border-radius: 8px;
    border: solid 1px #cbd5e1;
    color: #111111;
    width: 100%;
    height: 40px;
    padding: 0 6px;

    &::placeholder {
      color: #64748b;
    }
    ::-webkit-input-placeholder {
      color: #64748b;
    }

    &:focus {
      border: 1px solid #111111;
      outline: none;
    }

    &::after {
      border: solid 1px #cbd5e1;
    }

    &.error {
      border: solid 1.5px #ed4337 !important;
      &:focus {
        border: solid 1.5px #ed4337 !important;
        outline: none;
      }
    }
  }

  .show-icon {
    margin: auto 0;
    user-select: none;
    img {
      filter: contrast(0.5);
    }
    &.right {
      margin-left: -30px;
      // cursor: pointer;
    }
    &.left {
      margin: auto 10px;
      // cursor: pointer;
    }
  }

  .text-validation {
    font-size: 12px;
    font-size: Manrope;
    color: #4c494a;
    // position: absolute;
    //   top: -10;
    // bottom: -20px;
    left: 0;
    //   right: 0;
    &.error {
      color: #ed4337;
    }
  }
}

.input-autocomplete {
  position: relative;
  width: 300px;

  .selected-options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    margin-bottom: 4px;
    min-height: 38px; // set min-height agar baris selalu sejajar

    .selected-option {
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 2px 6px;
      margin-right: 4px;
      margin-bottom: 4px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      .remove-option {
        margin-left: 4px;
      }
    }

    .clear-selection {
      margin-left: auto;
      cursor: pointer;
      color: blue;
    }
  }

  input {
    width: calc(100% - 8px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    margin-bottom: 4px;
  }

  .options {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: calc(100% - 2px);
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;

    .option {
      padding: 8px;
      cursor: pointer;

      &:hover {
        background-color: #f0f0f0;
      }

      &.selected {
        background-color: #c4c4c4;
      }
    }
  }
}

.date-pick {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #cbd5e1;
  color: #111111;
  padding: 0 6px;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #fbb12f;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: #f4cb84;
}
.react-datepicker-wrapper {
  width: 100%;
}

.banner-comp {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #f8fafc;
  border: 1px solid #cbd5e1;

  .icon {
    // width: 10%;
    display: flex;
    margin: 0 5%;
    .alert-icon {
      filter: contrast(0.5);
    }
  }

  &.error {
    background: #ffe8e8;
    border: 1px solid #ff9494;
    .icon {
      .alert-icon {
        filter: invert(45%) sepia(95%) saturate(409%) hue-rotate(314deg)
          brightness(91%) contrast(103%);
      }
    }
  }

  &.info {
    background: #eff8ff;
    border: 1px solid #5cabff;
    .icon {
      .alert-icon {
        filter: invert(69%) sepia(13%) saturate(7091%) hue-rotate(185deg)
          brightness(97%) contrast(109%);
      }
    }
  }

  &.warning {
    background: #fff7ef;
    border: 1px solid #ffb97b;
    .icon {
      .alert-icon {
        filter: invert(34%) sepia(97%) saturate(512%) hue-rotate(349deg)
          brightness(97%) contrast(97%);
      }
    }
  }

  &.success {
    background: #edfff5;
    border: 1px solid #39c875;
    .icon {
      .alert-icon {
        filter: invert(37%) sepia(80%) saturate(493%) hue-rotate(67deg)
          brightness(93%) contrast(86%);
      }
    }
  }

  .message {
    width: 100%;
    text-align: start;
    font-weight: 500;
    font-size: 14px;
  }
}

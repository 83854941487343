.auth_layout {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  .auth_default {
    background-color: #231f20;
    height: 100%;
    position: relative;
    .auth__subheader {
      position: absolute;
      align-items: center;
      left: 53px;
      //   right: 0;s

      .auth__logo {
        height: 106px;
        object-fit: contain;
      }

      .auth__title {
        //   font-family: Quicksand;
        color: white;
        border-bottom: 4px solid #ffde17;
        //   font-weight: bold;
        font-size: 36px;
      }
    }
    .auth__left-vector {
      width: 80%;
      // margin-top: 40px;
      max-width: 600px;
    }
  }
  .auth_menu {
    height: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;

    .login_page {
      padding: 0 10vw;
      margin: auto;
      .auth__checkbox {
        .auth__checkbox-input ~ .auth__checkbox-label {
          cursor: pointer;
          margin-bottom: 0;
          &::before {
            content: "";
            height: 12px;
            width: 12px;
            vertical-align: middle;
            // background-color: #dddddd;
            border: 1px solid;
            border-radius: 2px;
          }
          &::after {
            content: "";
            height: 5px;
            width: 11px;
            position: absolute;
            display: block;
            margin-left: 1px;
            border-bottom: 2px solid white;
            border-left: 2px solid white;
            -webkit-transform: rotate(-45deg) scale(1);
            transform: rotate(-45deg) scale(1);
          }

          .auth__checkbox-text {
            font-size: 14px;
            letter-spacing: 0.47px;
            color: #231f20;
            user-select: none;
            margin-left: 5px;
          }
        }

        .auth__checkbox-input:checked ~ .auth__checkbox-label {
          &::before {
            background-color: #231f20;
            border: none;
          }
          &::after {
            border-color: #ffde17;
          }
        }
      }
      .auth__link,
      .auth__text {
        font-size: 14px;
        letter-spacing: 0.47px;
        color: #231f20;
        user-select: none;

        &.auth__text-gold,
        &.auth__link-gold,
        .auth__text-gold,
        .auth__link-gold {
          color: #ffcc00;
        }

        &.auth__text-medium,
        &.auth__link-medium {
          font-weight: 500;
        }
      }
    }
    label {
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 992px) {
  .auth_layout {
    .auth_default {
      .auth__left-vector {
        width: 50%;
      }
    }
  }
}
